import algoliasearch from 'algoliasearch/lite';
import {
  InstantSearch,
  ClearRefinements,
  SearchBox,
  Pagination,
  Highlight,
  Configure,
  connectHits,
  connectRange,
  RefinementList,
} from 'react-instantsearch-dom';
import {
  GoogleMapsLoader,
  GeoSearch,
  Marker,
} from 'react-instantsearch-dom-maps';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import Rheostat from 'rheostat';
import withURLSync from './URLSync';
import './App.css';

const searchClient = algoliasearch(
  'AN9FHIJ8B6',
  '557dec326745755fc32bd5dfb1a3bdd2'
);

const App = props => (
  <InstantSearch
    searchClient={searchClient}
    indexName="tst_nextgenspaces_nomads"
    searchState={props.searchState}
    createURL={props.createURL}
    onSearchStateChange={props.onSearchStateChange}
  >

    <Configure aroundLatLngViaIP={true} />
    <Map />
    <Header />    
    <div className="col-sm-2">
      <Filters />  
    </div> 
    <div className="col-sm-10">  
      <Results />
    </div>      

  </InstantSearch>
);

function Header() {
  return (
    <header className="navbar navbar-static-top aisdemo-navbar">
      <a
        href="https://www.nextgenspaces.com"
        className="is-logo"
      >
        <img
          alt="next gen spaces"
          src="https://www.nextgenspaces.com/img/next-gen-spaces-logo.png"
          width={100}
        />
      </a>
      <i className="fa fa-search" />
      <SearchBox />
    </header>
  );
}

function Map() {
  return (
    <Fragment>
      <div className="search-filters">
        <div className="aisdemo--right-column">
          <GoogleMapsLoader
            apiKey="AIzaSyDa4-ssBOUXImKINcrI7HYmhIysF9QNP7g"
            endpoint="https://maps.googleapis.com/maps/api/js?v=weekly"
          >
            {google => (
              <GeoSearch google={google} minZoom={2}>
                {({ hits }) => (
                  <Fragment>
                    {hits.map(hit => (
                      <Marker key={hit.objectID} hit={hit} />
                    ))}
                    <ClearRefinements
                      className="ClearGeoRefinement"
                      transformItems={items =>
                        items.filter(item => item.id === 'boundingBox')
                      }
                      translations={{
                        reset: 'Clear the map refinement',
                      }}
                    />
                  </Fragment>
                )}
              </GeoSearch>
            )}
          </GoogleMapsLoader>
        </div>
      </div>

    </Fragment>
  );
}

const divStyle = {
  fontSize: 12,
  color: '#9A9A9A',
  padding: 50
};

function Filters() {
  return (
    <Fragment>
      <div className="aisdemo--left-column">
          <div className="aisdemo-filters" style={divStyle}>

            <div className="row" style={{color:'#000'}}>
              <h5>Apps</h5>
            </div>
            <div className="row">
              <RefinementList attribute="app" limit={4} />
            </div>       
            <br />     

            <div className="row" style={{color:'#000'}}>
              <h5>Category</h5>
            </div>
            <div className="row">
            <RefinementList attribute="categorySlug" limit={3} showMore />
            </div>   
            <br />                 

            <div className="row" style={{color:'#000'}}>
              <h5>Partners</h5>
            </div>
            <div className="row">
            <RefinementList attribute="partner" limit={3} showMore />
            </div>   
            <br />                

            <div className="row" style={{color:'#000'}}>
              <h5>Conditions</h5>
            </div>
            <div className="row">
            <RefinementList attribute="conditions" limit={3} showMore />
            </div>   
            <br />                

            <div className="row" style={{color:'#000'}}>
              <h5>Price Range</h5>
            </div>
            <div className="row">
            <ConnectedRange attribute="_price" />
            </div>  
    
          </div>

          <div className="row">
            <div id="stats" />
          </div>
      </div>

    </Fragment>
  );
}


function OptionCapacity({ label, value }) {
  return <option value={value}>{label}</option>;
}

OptionCapacity.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
};


const MyHits = connectHits(({ hits }) => {
  const hs = hits.map(hit => <HitComponent key={hit.objectID} hit={hit} />);
  return <div id="hits">{hs}</div>;
});

function HitComponent({ hit }) {
  return (
    <div className="hit col-sm-3">   
      <a href={hit.permalink} target="_blank"> 
      <div className="pictures-wrapper">       
        <img className="picture" alt={hit.title} src={hit.thumbnail} width="100%" height="220" />
      </div>
      <div className="infos">
        <h4 className="media-heading">
          <Highlight attribute="title" hit={hit} />
        </h4>
        <HitDescription hit={hit} />
      </div>
      </a>
    </div>
  );
}

function HitDescription({ hit }) {
  return (
    <p>
      <Highlight attribute="partner" hit={hit} />,{' '}
      {hit.categorySlug},{' '}
      {'> '}{hit._price}{'€'}
    </p>
  );
}

HitComponent.propTypes = {
  hit: PropTypes.object,
};

function Results() {
  return (
    <div className="container-fluid" id="results">
      <div className="row">
        <MyHits />
      </div>
      <div className="row">
        <Pagination />
      </div>
    </div>
  );
}

class Range extends Component {
  static propTypes = {
    min: PropTypes.number,
    max: PropTypes.number,
    currentRefinement: PropTypes.object,
    refine: PropTypes.func.isRequired,
    canRefine: PropTypes.bool.isRequired,
  };

  state = { currentValues: { min: this.props.min, max: this.props.max } };

  componentDidUpdate(prevProps) {
    if (
      this.props.canRefine &&
      (prevProps.currentRefinement.min !== this.props.currentRefinement.min ||
        prevProps.currentRefinement.max !== this.props.currentRefinement.max)
    ) {
      this.setState({
        currentValues: {
          min: this.props.currentRefinement.min,
          max: this.props.currentRefinement.max,
        },
      });
    }
  }

  onValuesUpdated = sliderState => {
    this.setState({
      currentValues: { min: sliderState.values[0], max: sliderState.values[1] },
    });
  };

  onChange = sliderState => {
    if (
      this.props.currentRefinement.min !== sliderState.values[0] ||
      this.props.currentRefinement.max !== sliderState.values[1]
    ) {
      this.props.refine({
        min: sliderState.values[0],
        max: sliderState.values[1],
      });
    }
  };

  render() {
    const { min, max, currentRefinement } = this.props;
    const { currentValues } = this.state;
    return min !== max ? (
      <div>
        <Rheostat
          min={min}
          max={max}
          values={[currentRefinement.min, currentRefinement.max]}
          onChange={this.onChange}
          onValuesUpdated={this.onValuesUpdated}
        />
        <div className="rheostat-values">
          <div>{currentValues.min}</div>
          <div>{currentValues.max}</div>
        </div>
      </div>
    ) : null;
  }
}

const ConnectedRange = connectRange(Range);

export default withURLSync(App);
